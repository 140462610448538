import React, { useState, useRef, useEffect, useContext } from 'react';
import { GetWeatherReport } from '../../Services/area-map';
import RoutesData from './routeData';
import { Context } from '../../App';
import { getWeatherIconClass } from '../../Util/helper';
import { toast } from 'react-toastify';

export default function SearchContent({ containerId, viewMode, showGetDirections }) {

  const { latitude, longitude, destinationAddress, setStartingAddress } = useContext(Context);
  const [address, setAddress] = useState('');
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  });
  const [showWeatherDetails, setShowWeatherDetails] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [selectedTravelMode, setSelectedTravelMode] = useState('DRIVING');
  const autoFillElement = useRef(null);
  const [weatherApiCalled, setWeatherApiCalled] = useState(false)
  const [loading, setLoading] = useState(false);

  const getLocalDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const minDateString = getLocalDateString(today);
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 6);
  const maxDateString = getLocalDateString(maxDate);

  useEffect(() => {
    if (window._isMapLoaded) {
      const autocomplete = new google.maps.places.Autocomplete(autoFillElement.current, {
        types: ['geocode'],
      });
      autocomplete.setFields(['address_components', 'formatted_address']);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place || !place.formatted_address) return;
        setStartingAddress(place.formatted_address);
        setAddress(place.formatted_address)
      });
    }
  }, [window._isMapLoaded]);


  const filterForecastByDate = (selectedDate) => {
    if (!weatherData || !selectedDate) return [];
    const desiredDate = new Date(selectedDate).toLocaleDateString('en-US');
    return weatherData.properties.periods.filter(period => {
      const periodDate = new Date(period.startTime.split('T')[0]).toLocaleDateString('en-US');
      return periodDate === desiredDate;
    }).slice(-2);
  }

  const handleTravelModeChange = (mode) => {
    if (address === '') {
      toast('Please enter your Starting Address.', { position: toast.POSITION.BOTTOM_CENTER, className: 'areamap-info', autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: false });
      return;
    }
    setSelectedTravelMode(mode);
  };

  const toggleWeatherDetails = () => {
    setShowWeatherDetails(!showWeatherDetails);
    if (latitude && longitude && !weatherApiCalled) {
      setLoading(true);
      GetWeatherReport(latitude, longitude, (data) => {
        setWeatherData(data);
        setLoading(false);
      });
      setWeatherApiCalled(true);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
  };

  const weatherClassName = () => {
    const periods = filterForecastByDate(selectedDate);
    if (periods.length === 0) return '';
    const daytimePeriods = periods.filter(period => period.isDaytime);
    if (daytimePeriods.length === 0) return '';
    let conditions = new Set();
    daytimePeriods.forEach(period => {
      const forecast = period.shortForecast.toLowerCase();
      if (forecast.includes('sunny')) conditions.add('sunny-class');
      if (forecast.includes('snow')) conditions.add('snow-class');
      if (forecast.includes('thunderstorms')) conditions.add('stormy-class');
      if (forecast.includes('rain')) conditions.add('rainy-class');
      if (forecast.includes('cloudy')) conditions.add('cloudy-class');
    });
    return `${Array.from(conditions).map(cls => `${cls}`).join(' ')}`;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setStartingAddress(address);
    }
  };


  return (<>
    {showGetDirections ? <input type="checkbox" id={`destination-${containerId}`} className='s-d-n'  checked /> :
    <input type="checkbox" id={`destination-${containerId}`} className='s-d-n' />}
    <div className="s-br-2 m-t-12 searchContent-container" style={{ display: viewMode !== 'pointsOfInterest' ? '' : 'none' }}>
      <div className={`p-tb-12 s-d-f s-f-jc-c s-f-ai-c get-direction-wrapper ${showWeatherDetails ? `s-p-r weather-active ${weatherClassName()}` : ''}`} xstyle={{ minHeight: '85px' }}>
        <div className='s-d-b s-h-100 direction-btn' style={{ minWidth: '0' }}>
          <label htmlFor={`destination-${containerId}`} className='f-s-3 f-w-4 s-br-2 s-c-p s-d-f s-f-jc-c s-f-ai-c direction-label'>
            <i className='m-r-4 aga-directions' />
            <span className='s-ws-nw s-o-h s-to-e'><b className='f-w-6'>Directions to&nbsp;</b>{destinationAddress}</span>
          </label>
        </div>
        <div className='s-f-ai-c s-f-fg-1 s-br-2 g-100-p-t-20 s-d-n getDirections'>
          <label className='f-s-3 f-w-5 p-l-16 p-l-0-l s-w-100-m c-2 s-p-r direction-input'>Starting Address
            <input type="text" id={`startingAddress-${containerId}`} placeholder='Enter your Starting Address' className='f-s-2 f-w-4 p-tb-8 s-w-100 s-b-n s-b-t startingAddress' value={address} onChange={(e) => setAddress(e.target.value)} onKeyDown={handleKeyPress} ref={autoFillElement} />
            <i className='s-p-a aga-radio-button-unchecked' />
          </label>
          <div className='s-d-f s-w-50 s-w-100-m xm-l-16 xm-l-0-m date-weather'>
            <label className='f-s-3 f-w-5 s-w-100 c-2 s-p-r date-picker'>Travel Date
              <input type="date" id={`date-${containerId}`} value={selectedDate} className='f-s-2 f-w-4 p-tb-8 s-d-b s-w-100 s-c-p s-b-n s-b-t' onChange={(e) => setSelectedDate(e.target.value ? e.target.value : new Date().toISOString().split('T')[0])} min={minDateString} max={maxDateString} />
              {/* <i className='s-p-a aga-event_note' /> */}
            </label>
            {/* <label className='s-d-f s-f-as-fe s-f-ai-c s-f-jc-c s-c-p p-4 m-b-8 m-r-4 weather-forecast-btn' style={{ fontSize: '11px', borderRadius: '16px' }} onClick={toggleWeatherDetails}><span className='f-s-1 f-w-5' style={{ width: 'max-content' }}>Weather Forecast&nbsp;</span><i className='aga-wi-day-storm-showers' /></label> */}
          </div>
        </div>
      </div>
      {/* <div className={`s-f-jc-c s-f-ai-c m-b-16 s-d-n weather-data ${weatherClassName()} ${weatherData ? 'p-lr-100 p-tb-20 g-200-p-lr-30' : 'p-tb-50 s-b-g'}`} style={{ display: showWeatherDetails ? '' : 'none' }} >
        <div className={`s-w-100 weather-detailed-section ${weatherData ? 's-d-f s-f-jc-sb s-f-fw-w' : ''}`} style={{ maxWidth: '850px' }}>
          {loading ? (<div className='s-d-f s-f-jc-c s-f-ai-c s-w-100 loader-container'><div className='s-br-50 direction-loader' /></div>) : weatherData ? (
            filterForecastByDate(selectedDate).length > 0 && (
              filterForecastByDate(selectedDate).map((period, index) => (
                <div className={`s-d-f s-f-fd-c p-tb-12 p-lr-20 m-8 s-br-2 weather-details ${period.isDaytime == false ? 's-b-b s-c-w' : 's-b-w'}`} key={index} >
                  <div className='s-d-f s-f-ai-fs'>
                    {period.isDaytime == false ? (<>
                      <div className='s-d-f s-f-fd-c' style={{ width: 'calc(100% - 100px)' }}>
                        <span className='f-w-5'>Night</span>
                        <span className='f-s-2 f-w-5 m-b-4' key={index}>{period.shortForecast}</span>
                      </div>
                      <div className='s-p-r s-h-100' style={{ width: '100px' }}>
                        <i className={`s-d-f s-f-jc-c s-f-ai-c s-p-a weather-main ${getWeatherIconClass(period.shortForecast, period.isDaytime)}`} />
                      </div>
                    </>) :
                      (<>
                        <div className='s-d-f s-f-fd-c' style={{ width: 'calc(100% - 100px)' }}>
                          <span className='f-w-5'>Day</span>
                          <span className='f-s-2 f-w-5 m-b-4' key={index}>{period.shortForecast}</span>
                        </div>
                        <div className='s-p-r s-h-100' style={{ width: '100px' }}>
                          <i className={`s-d-f s-f-jc-c s-f-ai-c s-p-a weather-main ${getWeatherIconClass(period.shortForecast, period.isDaytime)}`} />
                        </div>
                      </>)}
                  </div>
                  <span className='s-d-f s-f-ai-c l-h-2 f-s-2 f-w-4 details-sections' style={{ gap: '2px' }}>
                    <i className='s-p-r aga-wi-thermometer'></i>{period.temperature} ° F
                  </span>
                  <span className='s-d-f s-f-ai-c l-h-2 f-s-2 f-w-4 details-sections'><i className="s-p-r aga-wi-strong-wind" style={{ marginLeft: '-1px' }} />Wind : {period.windDirection} {period.windSpeed}</span>
                  {period.dewpoint ? <span className='s-d-f s-f-ai-c l-h-2 f-s-2 f-w-4 details-sections'><i className='s-p-r aga-wi-dewpoint' style={{ marginLeft: '-3px' }} />  Dew Point: {period.dewpoint.value.toFixed(0)} ° C</span> : ''}
                  {period.probabilityOfPrecipitation.value !== null ? <span className='s-d-f s-f-ai-c l-h-2 f-s-2 f-w-4 details-sections' style={{ gap: '2px' }}><i className='s-p-r aga-wi-raindrop' /> Probability Of Precipitation :  {period.probabilityOfPrecipitation.value} %</span> : ''}
                  {period.relativeHumidity ? <span className='s-d-f s-f-ai-c l-h-2 f-s-2 f-w-4 details-sections'> <i className='s-p-r aga-wi-humidity' /> Humidity : {period.relativeHumidity.value} %</span> : ''}
                </div>
              ))
            )
          ) :
            <span className='f-s-3 f-w-4 p-20 c-4'>Weather data not available for this location.</span>}
        </div>
      </div> */}
      <div className='p-tb-12 s-br-2 s-d-n travel-mode-options'>
        <label className={`f-s-2 f-w-4 p-tb-4 p-lr-12 s-d-f s-f-ai-c m-r-12 s-br-2 s-c-p ${selectedTravelMode === 'DRIVING' ? 'mode-active' : ''}`} onClick={() => handleTravelModeChange('DRIVING')}>
          <i className='f-s-6 m-r-4 aga-driving' />Driving
        </label>
        <label className={`f-s-2 f-w-4 p-tb-4 p-lr-12 s-d-f s-f-ai-c s-br-2 s-c-p ${selectedTravelMode === 'TRANSIT' ? 'mode-active' : ''}`} onClick={() => handleTravelModeChange('TRANSIT')}>
          <i className='f-s-6 m-r-4 aga-transit' />Mass Transit
        </label>
      </div>
      {address !== '' && <RoutesData setAddress={setAddress} selectedTravelMode={selectedTravelMode} selectedDate={selectedDate} />}
    </div>
  </>)
}