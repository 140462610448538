import { useEffect, useState, useRef, useContext } from 'react';
import { Context } from '../../App';
import PointsOfInterest from './pointsOfInterest';
import SearchContent from './searchContent'
import { customMapStyles, imageToBase64 } from '../../Util/helper';

export default function AreaMap({ containerId, hideDrivingDirections, hidePOI, hidestreetView }) {

  const { latitude, longitude, zoomLevel, destinationAddress, mapLoaded, setMapLoaded, mapImageBase64, setMapImageBase64, viewMode, setViewMode } = useContext(Context);
  const [fullscreen, setFullscreen] = useState(false);
  const [streetViewNotAvailable, setStreetViewNotAvaialble] = useState(false);
  const [nearestStreetViewAttempted, setNearestStreetViewAttempted] = useState(false);
  const [mapviewLoaded, setMapviewLoaded] = useState(false);
  const mapRef = useRef(null);
  const streetViewRef = useRef(null);
  const nearestStreetViewRef = useRef(null);
  const streetViewAttemptedRef = useRef(false);

  const isIOS = /iPhone|iPad/.test(navigator.userAgent); 

  const handleFullscreenChange = (event) => {
    if (!document.fullscreenElement ) {
      setFullscreen(false);
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Escape' && fullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  useEffect(() => {
    imageToBase64('https://avm-service-stage.idcrealestate.com/assets/images/areamap.png', (base64String) => {
      setMapImageBase64(base64String);
    });
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setMapLoaded(false);
    const handleClick = (event) => {
      if (event.target && event.target.id === `map-container-${containerId}`) {
        loadMap();
        const pinElement = document.querySelector(`.pin-${containerId}`);
        if (pinElement) {
          pinElement.style.display = 'none';
        }
        document.getElementById(`map-container-${containerId}`).classList.add("clicked");
      }
    };

    const mapContainer = document.getElementById(`map-container-${containerId}`);
    if (viewMode === 'map' && !mapLoaded) {
      // setMapLoaded(true);
      mapContainer.addEventListener('click', handleClick);
    } else if (viewMode === 'map' && hidestreetView && !mapviewLoaded) {
      mapContainer.addEventListener('click', handleClick);
      setMapviewLoaded(true);
      setMapLoaded(true);
    } else if ((viewMode === 'map') && hidestreetView && mapviewLoaded) {
      setMapLoaded(true);
      document.querySelector('.pin').style.display = 'none';
      document.getElementById(`map-container-${containerId}`).classList.add("clicked");
    } else {
      if (!mapRef.current) {
        loadMap();
      }
      setMapLoaded(true);

    }
    const handleViewModeChange = (newViewMode, mapTypeId) => {
      if (newViewMode === viewMode) return;
      setViewMode(newViewMode);
      setMapLoaded(true);
      if (mapRef.current) {
        mapRef.current.setMapTypeId(mapTypeId);
        const pinElement = document.querySelector(`.pin-${containerId}`);
        if (newViewMode === 'street' && !hidestreetView) {
          if (streetViewRef.current  ) {
            mapRef.current.setStreetView(streetViewRef.current);
            streetViewRef.current.setVisible(true);
            document.getElementById(`map-container-${containerId}`).classList.add("clicked")
            if (pinElement) {
              pinElement.style.display = 'none';
            }          
          }else if (nearestStreetViewRef.current ) {
            mapRef.current.setStreetView(nearestStreetViewRef.current);
            nearestStreetViewRef.current.setVisible(true);
            document.getElementById(`map-container-${containerId}`).classList.add("clicked")
            if (pinElement) {
              pinElement.style.display = 'none';
            }          
          }else if (!streetViewAttemptedRef.current) {
            loadStreetView();
            document.getElementById(`map-container-${containerId}`).classList.add("clicked")
            if (pinElement) {
              pinElement.style.display = 'none';
            }
          }
          mapRef.current.setMapTypeId('satellite');
          // mapRef.current.setMapTypeId('roadmap');
        }else {
          if (streetViewRef.current) {
            streetViewRef.current.setVisible(false);
          }else if (nearestStreetViewRef.current) {
            nearestStreetViewRef.current.setVisible(false);
          }
          if (pinElement) {
            pinElement.style.display = 'none';
          }
        }
      }
    
    };

    if (!hidestreetView) {
      document.getElementById(`streetview-${containerId}`).addEventListener('click', () => handleViewModeChange('street', 'roadmap'));
    };
    document.getElementById(`satelliteview-${containerId}`).addEventListener('click', () => handleViewModeChange('satellite', 'satellite'));
    document.getElementById(`mapview-${containerId}`).addEventListener('click', () => handleViewModeChange('map', 'roadmap'));

    document.addEventListener(`fullscreenchange-${containerId}`, handleFullscreenChange);

    return () => {
      mapContainer.removeEventListener('click', handleClick);
      document.removeEventListener(`fullscreenchange-${containerId}`, handleFullscreenChange);
    };

  }, [viewMode, hidestreetView ]);

  const loadMap = () => {
    const location = { lat: latitude, lng: longitude };
    const mapOptions = {
      zoom: zoomLevel,
      center: location,
      styles: customMapStyles,
      disableDefaultUI: true,
      fullscreenControl: false,
    };

    const map = new google.maps.Map(document.getElementById(`map-${containerId}`), mapOptions);
    mapRef.current = map;

    if (google && google.maps) {
      if (viewMode === 'street' && !streetViewAttemptedRef.current && !hidestreetView) {
        loadStreetView();
      }
      else {
        map.setMapTypeId(viewMode === 'satellite' ? 'satellite' : 'roadmap');
      }
    }

    new google.maps.Marker({ position: location, map });

    map.setTilt(0);

    const setupButtonListeners = () => {
      document.getElementById(`zoom_in-${containerId}`).addEventListener('click', () => {
        let currentZoom = map.getZoom();
        if (currentZoom < 20) map.setZoom(currentZoom + 1);
      });

      document.getElementById(`zoom_out-${containerId}`).addEventListener('click', () => {
        let currentZoom = map.getZoom();
        if (currentZoom > 5) map.setZoom(currentZoom - 1);
      });

      document.getElementById(`fullscreen-${containerId}`).addEventListener('click', () => {
        const mapContainer = document.getElementById(`map-fullscreen-${containerId}`);
        if (!document.fullscreenElement) {
          mapContainer.requestFullscreen();
          setFullscreen(true);
        } else {
          document.exitFullscreen();
          setFullscreen(false);
        }
      });
    };
    setupButtonListeners();
    setMapLoaded(true);
  };

  const loadStreetView = () => {
    if (streetViewAttemptedRef.current) return;
    streetViewAttemptedRef.current = true;
      const location = { lat: latitude, lng: longitude };
      const streetViewService = new google.maps.StreetViewService();
      streetViewService.getPanorama({ location: location, radius: 20 }, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          const panoramaLatLng = data.location.latLng;
          const angle = getBearings(location, panoramaLatLng);
          if (!streetViewRef.current) {
            const panorama = new google.maps.StreetViewPanorama(document.getElementById(`map-${containerId}`), {
              position: location,
              pov: { heading: 180 + angle, pitch: 0 },
              zoom: 0,
              enableCloseButton: false,
              showSources: false
            });
            streetViewRef.current = panorama;
          }
          mapRef.current.setStreetView(streetViewRef.current);
        } else {
          setStreetViewNotAvaialble(true);
          mapRef.current.setMapTypeId('roadmap');
          // mapRef.current.setMapTypeId('satellite');
          document.getElementById(`map-container-${containerId}`).classList.add("clicked")
          document.querySelector(`.pin-${containerId}`).style.display = 'none';
        }
      });
  };  

  const loadNearestStreetView = () => {
    if (!nearestStreetViewAttempted) {
      setNearestStreetViewAttempted(true);
      const location = { lat: latitude, lng: longitude };
      const streetViewService = new google.maps.StreetViewService();
      streetViewService.getPanorama({ location: location }, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          const panoramaLatLng = data.location.latLng;
          const angle = getBearings(location, panoramaLatLng);
          if (!nearestStreetViewRef.current) {
            const panorama = new google.maps.StreetViewPanorama(document.getElementById(`map-${containerId}`), {
              position: location,
              pov: { heading: 180 + angle, pitch: 0 },
              zoom: 0,
              enableCloseButton: false,
              showSources: false
            });
            nearestStreetViewRef.current = panorama;
            setNearestStreetViewAttempted(true);
            setStreetViewNotAvaialble(false);
          }
          mapRef.current.setStreetView(nearestStreetViewRef.current);
          setNearestStreetViewAttempted(true);
        } else {
          setStreetViewNotAvaialble(true);
          setNearestStreetViewAttempted(true);
          mapRef.current.setMapTypeId('roadmap');
          // mapRef.current.setMapTypeId('satellite');    
          document.getElementById(`map-container-${containerId}`).classList.add("clicked")
          document.querySelector(`.pin-${containerId}`).style.display = 'none';
        }
      });
    }
  }; 

  const getBearings = (location, panoramaLatLng) => {
    const point1 = new google.maps.LatLng(location.lat, location.lng);
    const point2 = panoramaLatLng;
    const bearing1 = google.maps.geometry.spherical.computeHeading(point1, point2);
    return bearing1;
  }
  

  return (<>
    <div id={`map-view-tabs-${containerId}`} className='m-b-16 s-br-2 s-d-f s-p-r map-view-tabs'>
      <a id={`mapview-${containerId}`} className={`s-c-p p-8 f-s-3 s-d-f s-f-jc-c s-f-ai-c s-td-n s-p-r ${viewMode === 'map' ? 'c-6 active-label' : 'c-2'} ${hidestreetView ? 's-w-50' : ''}`}><i className='aga-map'></i><b className='f-w-6'>Map View</b></a>
      <a id={`satelliteview-${containerId}`} className={`s-c-p p-8 f-s-3 s-d-f s-f-jc-c s-f-ai-c s-td-n s-p-r ${viewMode === 'satellite' ? 'c-6 active-label' : 'c-2'} ${hidestreetView ? 's-w-50' : ''}`}><i className='aga-satellite'></i><b className='f-w-6'>Satellite View</b></a>
      {!hidestreetView && (<a id={`streetview-${containerId}`} className={`s-c-p p-8 f-s-3 s-d-f s-f-jc-c s-f-ai-c s-td-n s-p-r ${viewMode === 'street' ? 'c-6 active-label' : 'c-2'}`}><i className='aga-streetview'></i><b className='f-w-6'>Street View</b></a>)}
      {/* <a id="pointsOfInterest" className={`s-c-p p-8 f-s-3 s-d-f s-f-jc-c s-f-ai-c s-td-n s-p-r ${viewMode === 'pointsOfInterest' ? 'c-6 active-label' : 'c-2'}`}><i className='aga-poi'></i><b className='f-w-6'>Nearby</b></a> */}
    </div>
    <div className={`p-t-4 s-br-2 map-section area-map `}>
      <div className='s-p-r' id={`map-fullscreen-${containerId}`}>
        <div id={`map-container-${containerId}`} className={`s-br-2 s-p-r map-container ${!mapLoaded ? '' : 'clicked'}`} style={{ backgroundImage: viewMode === 'street' && streetViewNotAvailable  || viewMode === 'map' ? `url(${mapImageBase64})` : '' }} >
          <div id={`map-${containerId}`} className={`s-w-100 s-br-2 s-o-h dynamic-map ${fullscreen ? 'fullscreen-map' : ''} `}style={{ backgroundImage: viewMode === 'street' || !mapLoaded || (viewMode === 'map' && hidestreetView && !mapLoaded) ? `url(${mapImageBase64})` : '', display: viewMode == 'street' && streetViewNotAvailable ? 'none' : '' }}></div>
          <div style={{ ...(viewMode === 'street' && streetViewNotAvailable ? { minHeight: '400px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } : { display: 'none' }) }}>
            <span className='f-s-3 f-w-6 s-ta-c'>The street view for this {nearestStreetViewAttempted && streetViewNotAvailable ? 'area' : 'property'} is not available from Google Maps.</span>
            {!nearestStreetViewAttempted && <span className='f-s-3 f-w-5 m-t-16 s-ta-c s-c-p s-td-u nearby-SV' style={{ color: 'var(--m-primary-color)' }} onClick={()=> loadNearestStreetView()}>Show me the nearest available street view.</span>}
          </div>
          <div className="pin f-s-3 f-w-6 s-ta-c" style={{ display: viewMode !== 'map' && !mapLoaded && !hidestreetView ? 'none' : '' }} data-text="CLICK TO VIEW MAP"></div>
          <div id={`map-action-${containerId}`} className="s-p-a map_action" style={{ display: viewMode === 'street' || !mapLoaded || (viewMode === 'map' && hidestreetView && !mapLoaded) ? 'none' : '' }}>
            <a aria-label="Map Zoom In" id={`zoom_in-${containerId}`} className="s-br-50 s-td-n map-btn"><i className="aga-add"></i><span className="s-br-2 p-4 tooltiptext">Zoom In</span></a>
            <a aria-label="Map Zoom Out" id={`zoom_out-${containerId}`} className="s-br-50 s-td-n map-btn"><i className="aga-remove"></i><span className="s-br-2 p-4 tooltiptext">Zoom Out</span></a>
            <a aria-label="Map Fullscreen" style={{display:  !isIOS ? '': 'none'}} id={`fullscreen-${containerId}`} className="s-br-50 s-td-n map-btn"><i className={fullscreen ? 'aga-fullscreen-exit' : 'aga-fullscreen'}></i><span className="s-br-2 p-4 tooltiptext">Full Screen</span></a>
          </div>
        </div>
        {!hidePOI && mapLoaded && <PointsOfInterest containerId={containerId} latitude={latitude} longitude={longitude} mapRef={mapRef} viewMode={viewMode} mapLoaded={mapLoaded} fullscreen={fullscreen} />}
      </div>
    </div>
  </>)
}