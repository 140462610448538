import React, { useState, useEffect, useRef } from 'react';
import { renderStepsHTML, getDirectionSymbol, handleStepClick } from '../../Util/helper'
import Modal from 'react-modal';
import DirectionsMap from './directionsMap'

export default function Directions({ destinationAddress, startingAddress, routeSummary, preview }) {
  const [directions, setDirections] = useState();
  const [totalDistance, setTotalDistance] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [activeSteps, setActiveSteps] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [browserInfo, setBrowserInfo] = useState(null); 

  const request = {
    origin: startingAddress,
    destination: destinationAddress,
    travelMode: 'DRIVING',
    provideRouteAlternatives: true,
  }

  let directionsService = new google.maps.DirectionsService();
  let directionsRenderer = new google.maps.DirectionsRenderer();

  useEffect(() => {
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        const selectedRoute = result.routes.find(route => route.summary === routeSummary);
        const modifiedResult = { ...result, routes: [selectedRoute] };
        directionsRenderer.setDirections(modifiedResult);
        setDirections(selectedRoute.legs[0].steps);
        setTotalDistance(selectedRoute.legs[0].distance.text);
        setTotalTime(selectedRoute.legs[0].duration.text);
      } else if (status === 'ZERO_RESULTS') {
        setTransitRoute(null);
        setDirections(null);
        setTotalDistance(null);
        setTotalTime(null);
      }
    })

  }, []);

  const handleGetDirectionsPrint = () => {
    setIsLoading(true);
    setModalIsOpen(true);
    const { browser, device, os } = detectBrowserAndDevice();
    setBrowserInfo({ browser, device, os });

    if (browser === "Safari" || device !== "iOS") {
      setTimeout(() => {
        setIsLoading(false);
      }, 6000);
    } else {
      alert(
        "Print does not support on your browser. Please use safari browser to print the driving direction."
      );
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getLogoSource = () => {
    const logoElement = document.querySelector('.logo');
    const logoPrintElement = document.querySelector('.logo-print');
    let logoSrc;
    if(logoPrintElement && logoPrintElement.src){
      logoSrc= logoPrintElement.src
    }else if (logoElement && logoElement.src) {
      logoSrc = logoElement.src;
    }
    
    if (logoSrc && logoSrc !== null) {
      if (logoSrc.startsWith('/')) {
        const host = window.location.origin;
        return `${host}${logoSrc}`;
      }
      return logoSrc;
    }
    return 'https://avm-service-stage.idcrealestate.com/assets/images/breport/logo.png';
  };

  const logoSrc = getLogoSource();

  
  const detectBrowserAndDevice = () => {
    const userAgent = navigator.userAgent;
    const width = window.innerWidth;
    let browser = "Unknown Browser";
  
    if (/Edg/i.test(userAgent)) {
      browser = "Edge"; 
    }
    else if (/Chrome/i.test(userAgent) && !/Edg/i.test(userAgent)) {
      browser = "Chrome";
    }
    else if (/Firefox/i.test(userAgent)) {
      browser = "Firefox";
    }
    else if (/Safari/i.test(userAgent) && !/CriOS/i.test(userAgent) && !/FxiOS/i.test(userAgent) && !/Edg/i.test(userAgent)) {
      browser = "Safari";
    }
    else if (/CriOS/i.test(userAgent)) {
      browser = "Chrome (iOS)";
    }
    else if (/FxiOS/i.test(userAgent)) {
      browser = "Firefox (iOS)";
    }
    else if (/Trident|MSIE/i.test(userAgent)) {
      browser = "Internet Explorer";
    }
  
    let device = "Desktop"; 
      if (/Android/i.test(userAgent)) {
      device = 'Android';
    }
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      device = 'iOS';
    }
    else if (/Mobi|Android/i.test(userAgent)) {
      device = 'Mobile';
    }
    else if (/Tablet|iPad/i.test(userAgent) || (width >= 600 && width <= 1024)) {
      device = 'Tablet';
    }
  
    let os = "Unknown OS"; 
  
    if (/Macintosh|Mac OS X/i.test(userAgent)) {
      os = "Mac OS";
    } else if (/Windows/i.test(userAgent)) {
      os = "Windows";
    } else if (/Linux|X11/i.test(userAgent)) {
      os = "Linux";
    }
  
    return { browser, device };
  };
  
  const isIOSNotSafari = browserInfo?.device === 'iOS' && browserInfo?.browser !== 'Safari';


  return (
    <div className='p-lr-100 p-b-30 s-b-g directions-map-container'>
      <div className='s-d-f s-f-jc-fe s-f-ai-c p-t-30 p-b-20 s-p-r directions-map-header'>
        {isLoading && !isIOSNotSafari && (<div className="s-d-f s-f-jc-c s-f-ai-c s-w-100 s-p-a loader-container"><div className="s-br-50 direction-loader"></div></div>)}
        <a className='s-d-f s-f-jc-c s-f-ai-c l-h-n s-c-p s-br-50 s-td-n c-6 p-0 print-btn' onClick={handleGetDirectionsPrint}><i className='f-s-6 aga-print' /></a>
      </div>
      <div id='driving-direction' className='driving-direction'>
        <h4 className='s-d-f s-f-ai-c p-16 f-s-4 f-w-6 m-tb-12 s-b-w'><i className="m-r-4 aga-radio-button-unchecked" />{startingAddress}</h4>
        <ul>
          {directions && directions.map((step, index) => (
            <li className={`s-w-100 s-d-f s-f-jc-sb p-tb-4 ${activeSteps.includes(index) ? 'active' : ''}`} style={{ color: '#363636' }} key={index} onClick={() => handleStepClick(index, setActiveSteps)}>
              <span className='f-s-3 s-d-f s-ta-l p-r-12 substep-left'>
                <span className={getDirectionSymbol(step.maneuver)} style={{ minWidth: '26px' }}></span>
                <span className='m-r-8 f-w-6 serial-number'>{index + 1}.</span>
                {renderStepsHTML(step.instructions)}
              </span>
              <span className='f-s-3 f-w-6 s-ta-r' xstyle={{ minWidth: '150px' }}>
                {step.distance.text.replace('mi', 'miles').replace('ft', 'feet')}
              </span>
            </li>
          ))}
        </ul>
        <h4 className='s-d-f s-f-ai-c p-16 f-s-4 f-w-6 m-tb-12 s-b-w'><i className="m-r-4 aga-destination" />{destinationAddress}</h4>
      </div>
     {!isIOSNotSafari &&
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Directions Map Modal" className="modal" overlayClassName="overlay">
        <DirectionsMap destinationAddress={destinationAddress} startingAddress={startingAddress} routeSummary={routeSummary} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} logoSrc={logoSrc} />
      </Modal>
    }
    </div>
  )
}