import React, { useState, useEffect, createContext } from 'react';
import HomePage from '../src/App/pages/home';
import CommunityMap from './App/Components/communityMap';
import {GetBoundaries} from '../src/Services/area-map'
import { GetBoundaryData } from '../src/Util/helper';

export const Context = createContext();

export default function App({ areaMapLatitude, areaMapLongitude, zoomLevel, destinationAddress, jsonSourceUrl, areaMapContainerId, CommunityMapContainerId, hideDrivingDirections, hidePOI , hidestreetView, showGetDirections}) {
  const [startingAddress, setStartingAddress] = useState('');
  const latitude = parseFloat(areaMapLatitude);
  const longitude = parseFloat(areaMapLongitude);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapImageBase64, setMapImageBase64] = useState('');
  const [viewMode, setViewMode] = useState('map');
  const isLatitudeValid = latitude != null && latitude !== '' && latitude >= -90 && latitude <= 90;
  const isLongitudeValid = longitude != null && longitude !== '' && longitude >= -180 && longitude <= 180;
  const [coords, setCoords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [centerLat, setCenterLat] = useState(null);
  const [centerLng, setCenterLng] = useState(null);
  const [bounds, setBounds] = useState([]);
  const [isApiSuccess, setIsApiSuccess] = useState(false);

  // useEffect(()=>{
  //   if(hidestreetView){
  //     setViewMode('map')
  //   }
  // },[hidestreetView])

  useEffect(() => {
    if ( !isLatitudeValid || !isLongitudeValid || latitude === 0 || longitude === 0 || isNaN(latitude) || isNaN(longitude)) {
      const containerElement = document.getElementById(areaMapContainerId);
      if (containerElement) {
        containerElement.style.display = 'none';
      }
    }
  }, [latitude, longitude, areaMapContainerId]);

  useEffect(() => {
    if ((centerLat !== null && centerLng !== null ) || !isApiSuccess) {
      const containerElement = document.getElementById(CommunityMapContainerId);
      if (containerElement) {
        if (centerLat === null || centerLng === null) {
          containerElement.style.display = 'none';
        }else{
          containerElement.style.display = 'block';
        }
      }
    }
  }, [centerLat, centerLng, CommunityMapContainerId, isApiSuccess]);

  useEffect(() => {
    if(jsonSourceUrl !== '')
    fetchBoundaryData();
  }, [jsonSourceUrl]);

  const fetchBoundaryData = async () => {
    try {
      const data = await GetBoundaries(jsonSourceUrl);
      const boundaryData = GetBoundaryData(data);
      // const boundaryData = GetBoundaryData(jsonData);
      if (boundaryData.Polygons && boundaryData.Polygons.length > 0) {
        setCoords(boundaryData.Polygons);
        const newBounds = new google.maps.LatLngBounds();
        boundaryData.Polygons.forEach(polygon => {
          polygon.Boundary.forEach((coordsArray) => {
            if (Array.isArray(coordsArray)) {
              coordsArray.forEach(coord => {
                const lat = parseFloat(coord[1]);
                const lng = parseFloat(coord[0]);
                newBounds.extend(new google.maps.LatLng(lat, lng));
              });
            } else {
              const lat = parseFloat(coordsArray.lat); 
              const lng = parseFloat(coordsArray.lng); 
              newBounds.extend(new google.maps.LatLng(lat, lng));
            }
          });
        });
        setBounds(newBounds);
        const center = newBounds.getCenter();
        const lat = parseFloat(center.lat());
        const lng = parseFloat(center.lng());
        setCenterLat(lat);
        setCenterLng(lng);
        setLoading(false);
        setIsApiSuccess(true);
      }
    } catch (error) {
      setIsApiSuccess(false);
    }
  };

  const renderContent = (containerId) => {
    if (!isLatitudeValid || !isLongitudeValid || latitude === 0 || longitude === 0 || isNaN(latitude) || isNaN(longitude)) {
      return null; 
    }

    if(jsonSourceUrl && jsonSourceUrl !==null && isApiSuccess && centerLat !== null && centerLng !== null) {
      return (
        <Context.Provider value={{latitude, longitude, destinationAddress, startingAddress, setStartingAddress, mapLoaded, setMapLoaded, mapImageBase64, setMapImageBase64, viewMode, setViewMode , bounds, setBounds, centerLat, setCenterLat, centerLng, setCenterLng , coords, setCoords }}>
          <CommunityMap jsonSourceUrl={jsonSourceUrl} hidePOI={hidePOI}/>
        </Context.Provider>
      )
    }else if(jsonSourceUrl ==='' && CommunityMapContainerId === ''){
      return(
        <Context.Provider value={{latitude, longitude, zoomLevel, destinationAddress, startingAddress, setStartingAddress, mapLoaded, setMapLoaded, mapImageBase64, setMapImageBase64, viewMode, setViewMode  }}>
          <HomePage containerId={containerId} hideDrivingDirections={hideDrivingDirections} hidePOI={hidePOI}  hidestreetView={hidestreetView} showGetDirections={showGetDirections} />
        </Context.Provider>
      )
    }
  };

  return <>{renderContent(areaMapContainerId)}</>;
}
